import React from "react";
import styled from "styled-components";

import { Box, Badge, List } from "../../components/Core";

import imgB1 from "../../assets/image/jpeg/blog-details-img-1.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);

const AddVehicleImage = () => (
  <>
    {/* <!-- Blog section --> */}
    <Post>
      <div>
        <iframe
          width="730"
          height="412"
          src="https://www.youtube.com/embed/T_0nNyHa7_o"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div>
        <h3>Adding A Vehicle Note</h3>
        <p>
          With LoanerHub you can add notes to a vehicle, allowing you to keep
          track of extra data about the vehicle.
        </p>
        <p>Go To The Vehicles Details Page:</p>
        <List>
          <li>Click 'VEHICLES' on the left menu bar</li>
          <li>
            Click the 'DETAILS' button in the 'ACTIONS' column for the vehicle
            you want to edit
          </li>
        </List>

        <p>Add Update A Vehicle Note:</p>
        <List>
          <li>Click 'NOTES' on the menu bar</li>
          <li>
            Click the three-dot menu on the upper right of the vehicle's
            details.
          </li>
          <li>Then click 'Add A Note'.</li>
        </List>
      </div>
    </Post>
  </>
);

export default AddVehicleImage;
